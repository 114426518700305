<template>
  <div class="flex grow justify-center px-4" role="region" aria-label="Verification code entry">
    <template v-if="step === 'contact'">
      <slot />
    </template>
    <template v-else-if="step === 'code'">
      <div
        class="flex w-full max-w-lg flex-col items-center space-y-7"
        :class="!showInDrawer ? 'justify-center' : ''"
      >
        <a
          v-if="!showInDrawer"
          class="text-grays-darkest cursor-pointer self-start"
          @click="step = 'contact'"
          href="#"
          role="button"
          aria-label="Go back to previous step"
          >&larr; Back</a
        >
        <h1 class="text-grays-darkest text-2xl font-semibold" id="otp-title">
          Enter verification code
        </h1>
        <p class="text-grays-darkest text-center flex flex-col sm:block items-center" id="otp-description">
          <span class="inline">Please enter the 4-digit code sent to</span>
          <span class="font-semibold py-1 sm:py-0 sm:px-1">{{
            formattedContact?.formatted || contact
          }}</span>
          <span class="inline">{{
            isWelcomePage || showInDrawer
              ? 'to verify your account.'
              : 'to complete your checkout.'
          }}</span>
        </p>
        <div 
          class="relative flex justify-center" 
          :class="showInDrawer ? 'gap-2' : 'gap-4 sm:gap-4'"
          role="group"
          aria-labelledby="otp-title"
          aria-describedby="otp-description"
        >
          <input
            ref="hiddenInput"
            v-model="code"
            type="tel"
            inputmode="numeric"
            pattern="[0-9]*"
            maxlength="4"
            autocomplete="one-time-code"
            class="fixed top-0 left-0 opacity-0 h-[100vh] w-[100vw] z-[-1]"
            @input="handleInput"
            @focus="handleFocus"
            @blur="isInputFocused = false"
            @paste="handlePaste"
            aria-label="Enter verification code"
            :aria-invalid="codeError"
            :aria-errormessage="codeError ? 'error-message' : undefined"
          />
          <div
            v-for="(digit, index) in displayDigits"
            :key="index"
            class="bg-grays-lighter focus:border-grays-dark border-grays-lighter disabled:text-grays-darkest text-grays-darkest inline-flex rounded-lg items-center justify-center text-2xl sm:text-3xl relative h-14 w-14 sm:h-20 sm:w-20"
            :class="{
              'border-2 border-red-500': codeError,
              'border-2 border-grays-dark': focusedIndex === index && !codeError,
              'cursor-text': focusedIndex === index,
              'cursor-default': focusedIndex !== index
            }"
            @click="focusHiddenInput"
            role="presentation"
          >
            <div class="relative h-8 flex items-center justify-center">
              <span class="absolute" aria-hidden="true">{{ digit }}</span>
              <div
                v-if="focusedIndex === index && isInputFocused"
                class="absolute h-8 w-0.5 bg-grays-dark cursor-animation"
                aria-hidden="true"
              ></div>
            </div>
          </div>
        </div>
        <span 
          v-if="codeError" 
          class="text-red-medium"
          role="alert"
          id="error-message"
        >The code entered is incorrect. Please check your code and try again.</span>
        <ZnButton
          :disabled="code.length !== 4"
          :class="`w-full text-center ${
            code.length !== 4 ? 'bg-grays-light text-white' : ''
          }`"
          @click="handleVerify()"
          :aria-label="loading || forceLoading ? 'Verifying code...' : 'Verify code'"
        >
          <span v-if="!(loading || forceLoading)">Verify code</span>
          <ZenniIconsIconLoading v-else class="text-white" aria-hidden="true" />
        </ZnButton>
        <div class="h-12">
          <span class="text-grays-darkest">
            Didn't get a code?
            <span v-if="timeout !== null" class="text-grays-dark" aria-live="polite">
              Resend in :{{ timeout }}
            </span>
            <button
              v-else
              type="button"
              class="text-grays-darkest underline underline-offset-2"
              @click="handleSendCode()"
              :disabled="loading"
              >Resend</button
            >
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useOtp } from '@/composables/api/useOtp'
import { toast } from '@/components/Toast'
import {
  ref,
  toRefs,
  computed,
  watch,
  onUnmounted,
  nextTick,
  useRoute,
} from '#imports'

const emit = defineEmits<{
  (event: 'verified', token: string): void
}>()

const props = defineProps({
  contact: {
    type: String,
    required: false,
    default: () => '',
  },
  isPhone: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  forceLoading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  showInDrawer: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  storeId: {
    type: String,
    required: false,
    default: undefined,
  },
})

const { forceLoading } = toRefs(props)
const timeoutDuration = 60
const contact = ref<string>(props.contact)
const codeError = ref<boolean>(false)
const timeout = ref<number | null>(null)
const timer = ref<ReturnType<typeof setInterval> | null>(null)
const step = ref<'contact' | 'code'>('contact')
const loading = ref<boolean>(false)

watch(
  () => props.contact,
  () => {
    contact.value = props.contact
  },
)
const isWelcomePage = computed(() => {
  return useRoute().path === '/welcome'
})

const formattedContact = computed<{ formatted: string; e164: string } | null>(
  () => {
    if (props.isPhone) {
      const parsedPhone = parsePhoneNumberFromString(contact.value, 'US')

      if (parsedPhone) {
        const formatted = parsedPhone.formatNational()
        const e164 = parsedPhone.format('E.164')

        return {
          formatted,
          e164,
        }
      }
    }

    return null
  },
)

const clearTimer = () => {
  if (timer.value !== null) {
    clearInterval(timer.value)
    timer.value = null
  }
}

const startTimer = () => {
  clearTimer()
  timer.value = setInterval(() => {
    if (timeout.value !== null && timeout.value > 1) {
      timeout.value -= 1
    } else {
      timeout.value = null
      clearTimer()
    }
  }, 1000)
}

const { start, verify } = useOtp()

const code = ref<string>('')
const hiddenInput = ref<HTMLInputElement | null>(null)
const focusedIndex = computed(() => code.value.length)

const displayDigits = computed(() => {
  const digits = code.value.split('')
  return Array(4).fill('').map((_, i) => digits[i] || '')
})

const focusHiddenInput = () => {
  hiddenInput.value?.focus()
}

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement
  code.value = input.value.replace(/\D/g, '').slice(0, 4)
}

const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault()
  const paste = event.clipboardData?.getData('text') || ''
  code.value = paste.replace(/\D/g, '').slice(0, 4)
}

const handleFocus = () => {
  isInputFocused.value = true
  handleClearError()
}

const handleClearError = () => {
  if (codeError.value) {
    code.value = ''
    codeError.value = false
  }
}

const handleVerify = async () => {
  if (loading.value) return
  
  try {
    loading.value = true
    if (code.value.length === 4) {
      const { data, error } = await verify({
        code: code.value,
        ...(props.isPhone
          ? { userPhoneNumber: formattedContact.value?.e164 }
          : { userEmail: contact.value }),
        storeId: props.storeId,
      })

      if (data.value) {
        emit('verified', data.value.token)
      } else if (error.value) {
        codeError.value = true
      }
    }
  } finally {
    loading.value = false
  }
}

const handleSendCode = async () => {
  if (loading.value) {
    return
  }
  try {
    reset()
    handleClearError()
    loading.value = true

    const { error } = await start(
      props.isPhone
        ? {
            userPhoneNumber: formattedContact.value?.e164,
            storeId: props.storeId,
          }
        : { userEmail: contact.value, storeId: props.storeId },
    )

    if (error.value) {
      toast.open({
        type: 'error',
        title: 'Error occured',
        content:
          error.value.statusCode === 404 && props.isPhone
            ? "Couldn't find your account. Try using your email address instead."
            : 'Could not find your user account. If the issue persists, contact support.',
      })
    } else {
      step.value = 'code'
      timeout.value = timeoutDuration
      startTimer()
    }
  } finally {
    loading.value = false
  }
}

const reset = () => {
  code.value = ''
  codeError.value = false
  clearTimer()
}

onUnmounted(() => {
  step.value = 'contact'
  reset()
})

watch(
  () => code.value,
  () => {
    if (code.value.length === 4) {
      handleVerify()
    }
  },
)

const isInputFocused = ref<boolean>(false)

watch(
  () => step.value,
  (newStep) => {
    if (newStep === 'code') {
      nextTick(() => {
        setTimeout(() => {
          hiddenInput.value?.focus()
        }, 300)
      })
    }
  }
)

defineExpose({ contact, loading, handleSendCode, reset })
</script>

<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.cursor-animation {
  animation: cursor-blink 1s step-end infinite;
}

@keyframes cursor-blink {
  50% {
    opacity: 0;
  }
}
</style>
